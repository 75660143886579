import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConfirmDialogService, LoadingService, OutletService, ProductService } from '@data-access/services';
import { Outlet, Product, ProductCategory, ResponseDTO, Unit } from '@models/index';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { Subject, first, takeUntil } from 'rxjs';
import { OutletComponent } from '../outlet/outlet.component';
import { PrimeDropdownField, PrimeTableColumn } from '@models/prime-interface/prime-interface';
import { AppDialogComponent } from "../../shared/app-dialog/app-dialog.component";
import { TextInputFieldComponent } from '../../shared/text-input-field/text-input-field.component';
import { TextareaFieldComponent } from '@components/shared/textarea-field/textarea-field.component';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { DropdownFieldComponent } from '@components/shared/dropdown-field/dropdown-field.component';
import { FileUploadComponent } from "../../shared/file-upload/file-upload.component";
import { ZorroTableComponent } from "../../zorro-table/zorro-table.component";
import { FieldType, TableColumn } from '@utils/index';

@Component({
  selector: 'app-outlet-products',
  standalone: true,
  templateUrl: './outlet-products.component.html',
  styleUrl: './outlet-products.component.css',
  imports: [OutletComponent, CardModule, CardModule, TableModule, ButtonModule, DialogModule,
    ToolbarModule, FileUploadModule, InputNumberModule, DropdownModule, InputTextModule, RippleModule,
    ReactiveFormsModule, ConfirmPopupModule, ToastModule, AppDialogComponent, TextInputFieldComponent, TextareaFieldComponent, AvatarModule, AvatarGroupModule, DropdownFieldComponent, FileUploadComponent, ZorroTableComponent]
})
export class OutletProductsComponent implements OnDestroy {

  constructor(private outletService: OutletService, private confirmDialogService: ConfirmDialogService,
    private loadingService: LoadingService,

    private messageService: MessageService, private productService: ProductService, private toastr: ToastrService) {
    this.outletService.outlet$.pipe(takeUntil(this.$destroy)).subscribe((result) => {
      this.outlet = result as Outlet;
    });
    this.fetchProducts(this.outlet?.id ?? 0);
    this.fetchProductCategories(this.outlet?.id ?? 0);
    this.fetchUnits(this.outlet?.storeId ?? 0);
  }

  products: Product[] = [];
  productTypes: PrimeDropdownField[] = [{ id: 0, name: "Perishable" }, { id: 1, name: "Non-Perishable" }];
  productType: number = 0;
  outlet: Outlet | undefined = undefined;
  units: Unit[] = [];
  productCategories: ProductCategory[] = [];
  addProductDialog: boolean = false;
  editProductDialog: boolean = false;
  selectedProduct: Product | undefined = undefined;

  tableCols: TableColumn[] = [
    { field: 'image', header: 'Image', fieldType: FieldType.Image },
    { field: 'name', header: 'Name', isSearchable: true },
    { field: 'productCategory', header: 'Category' },
    { field: 'unit', header: 'Unit' },
    { field: 'productType', header: 'Type' }];
  private $destroy = new Subject<void>();
  formSubmitted: boolean = false;
  selectedFile: File | null = null;


  onUpload($event: File) {
    if ($event) {
      this.selectedFile = $event;
    }
  }

  showDialog() {
    this.selectedFile = null;
    this.createProductForm.reset();
    this.addProductDialog = !this.addProductDialog;
    if (!this.addProductDialog) {
      this.createProductForm.reset();
      this.formSubmitted = false;
      Object.keys(this.createProductForm.controls).forEach(key => {
        const control = this.createProductForm.get(key);
        control?.markAsUntouched();
        control?.markAsPristine();
      });
    }
  }

  closeEditDialog() {
    this.selectedFile = null;
    this.editProductDialog = false;
    this.editProductForm.reset();
    this.formSubmitted = false;
    Object.keys(this.editProductForm.controls).forEach(key => {
      const control = this.editProductForm.get(key);
      control?.markAsUntouched();
      control?.markAsPristine();
    });
  }

  showEditDialog($event: number) {
    this.selectedFile = null;
    this.editProductDialog = !this.editProductDialog;
    if (!this.editProductDialog) {
      this.editProductForm.reset();
    }
    else {
      const product = this.products[$event];
      if (product) {
        this.selectedProduct = product;
        const selectedCategory = this.productCategories.find(cat => cat.id === product.productCategoryId) || null;
        const selectedUnit = this.units.find(unit => unit.id === product.unitId) || null;
        const selectedType = this.productTypes.find((types) => types?.name === product.productType) || null;

        this.editProductForm.patchValue({
          name: product.name,
          description: product.description,
          productCategory: selectedCategory,
          unit: selectedUnit,
          productType: selectedType
        });
      }
    }
  }

  createProductForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    description: new FormControl(''),
    productCategory: new FormControl<ProductCategory | null>(null, Validators.required),
    unit: new FormControl<Unit | null>(null, Validators.required),
    productType: new FormControl<PrimeDropdownField | null>(null, Validators.required),
    image: new FormControl<File | null>(null)
  });

  editProductForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    description: new FormControl(''),
    productCategory: new FormControl<ProductCategory | null>(null, Validators.required),
    unit: new FormControl<Unit | null>(null, Validators.required),
    productType: new FormControl<PrimeDropdownField | null>(null, Validators.required),
    image: new FormControl<File | null>(null)
  });

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  saveProduct() {
    this.formSubmitted = true;
    const formData = this.createProductForm.value;
    if (this.createProductForm.invalid) {
      return;
    }
    this.loadingService.show();
    const formData2 = new FormData();
    formData2.append('name', formData.name as string);
    formData2.append('description', formData.description ?? '');
    formData2.append('unitId', formData.unit?.id?.toString() ?? '0');
    formData2.append('productCategoryId', formData!.productCategory?.id?.toString() ?? '0');
    formData2.append('productType', formData.productType?.id?.toString() ?? '0');
    if (this.selectedFile) {
      formData2.append('image', this.selectedFile!, this.selectedFile?.name);
    }
    formData2.append('storeId', this.outlet?.storeId?.toString() ?? '0');
    this.productService.createProduct(formData2).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          this.createProductForm.reset();
          this.toastr.success(response.message);
          this.fetchProducts(this.outlet?.id ?? 0)
          this.showDialog();
          this.loadingService.hide();

        }
        else {
          this.toastr.error(response.message);
          this.loadingService.hide();
        }
      },
      error: (err) => {
        this.toastr.error("Something went wrong");
        this.loadingService.hide();
      },
    });
  }

  updateProduct() {
    this.formSubmitted = true;
    const formData = this.editProductForm.value;
    const productId = this.selectedProduct?.id ?? 0;
    if (this.editProductForm.invalid) {
      return;
    }
    this.loadingService.show()
    const formData2 = new FormData();
    formData2.append('id', productId.toString());
    formData2.append('name', formData.name as '');
    formData2.append('description', formData.description ?? '');
    formData2.append('unitId', formData.unit?.id?.toString() ?? '0');
    if (this.selectedFile) {
      formData2.append('image', this.selectedFile!, this.selectedFile?.name);
    }
    formData2.append('productCategoryId', formData.productCategory?.id?.toString() ?? '0');
    formData2.append('productType', formData.productType?.id?.toString() ?? '0');
    this.productService.updateProduct(formData2).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          this.toastr.success(response.message);
          this.fetchProducts(this.outlet?.id ?? 0);
          this.loadingService.hide()
          this.closeEditDialog();
        }
        else {
          this.toastr.error(response.message);
        }
      },
      error: (err) => {
        this.toastr.error("Something went wrong");
        this.loadingService.hide()

      },
    });
  }

  deleteProductAPI(id: number) {
    this.loadingService.show();
    this.productService.deleteProduct(id).pipe(first()).subscribe({
      next: (response: ResponseDTO) => {
        if (response.status) {
          this.fetchProducts(this.outlet?.id ?? 0)
          this.toastr.success("Product deleted successfully");
          this.loadingService.hide();

        }
        else {
          this.toastr.error(response.message);
          this.loadingService.hide();
        }
      },
      error: (err) => {
        this.toastr.error("Something went wrong");
        this.loadingService.hide();

      },
    });
  }

  deleteProduct(id: number) {
    const product = this.products.find(x => x.id === id);
    this.confirmDialogService.open({
      title: 'Delete Product',
      message: `Are you sure you want to delete ${product?.name}?`,
      confirmLabel: 'Yes, Confirm',
      cancelLabel: 'No, Cancel',
      confirmColor: '#FF2230',
      cancelTextColor: '#580c8b',
      cancelBorderColor: '#580c8b'
    }).then((result) => {
      if (result) {
        this.deleteProductAPI(id);
      }
    });
  }

  public fetchProducts(outlet: number) {
    if (outlet === 0) return;
    this.outletService.getOutletProducts(outlet).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.products = result.data as Product[];
        }
        else {
          console.log("something went wrong");
        }
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  public fetchProductCategories(outlet: number) {
    if (outlet === 0) return;
    this.outletService.getOutletProductCategories(outlet).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.productCategories = result.data as ProductCategory[];
        }
        else {
          console.log("something went wrong");
        }
      },
      error: () => {
        console.log("Something went wrong");
      }
    });
  }

  public fetchUnits(store: number) {
    if (store === 0) return;
    this.outletService.getOutletUnits(store).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.units = result.data as Unit[];
        }
        else {
          console.log("else something went wrong");
        }
      },
      error: () => {
        console.log("error Something went wrong");
      }
    });
  }
}
