import { Component, NgZone, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { AuthLayoutComponent } from '@components/layouts';
import { AuthService, LoadingService } from '@data-access/services';
import { LoginDTO, LoginResponseDTO, ResponseDTO } from '@models/index';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { PasswordFieldComponent } from "../../shared/password-field/password-field.component";
import { TextInputFieldComponent } from '@components/shared/text-input-field/text-input-field.component';
import { environment } from '@environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { KioskService } from '@data-access/services/kiosk/kiosk.service';
import { Kiosk } from '@models/kiosk/kiosk';
import { toastrConfig } from '@utils/shared/shared';
import { SessionTimeoutService } from '../../../services/sesstion-timeout.service';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    AuthLayoutComponent, PasswordFieldComponent, TextInputFieldComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {

  ngOnInit(): void {
    // Only execute window-dependent code in browser environment
    if (isPlatformBrowser(this.platformId)) {
      // @ts-ignore
      window.onGoogleLibraryLoad = () => {
        // @ts-ignore
        google.accounts.id.initialize({
          client_id: environment.googleOauthKey,
          callback: this.googleLogin.bind(this),
          auto_select: false,
          cancel_on_tap_outside: true
        });
        // @ts-ignore
        google.accounts.id.renderButton(
          // @ts-ignore
          document.getElementById("googleSignInDiv"), { theme: "outline", size: "large", width: "100%", logo_alignment: "center" }
        );
        // @ts-ignore
        google.accounts.id.prompt((notification: PromptMomentNotification) => { });
      }
    }
  }

  loginFormSubmitted: boolean = false;
  isKiosk : boolean = false;

  constructor(private authService: AuthService, private toastr: ToastrService,
    public router: Router, private kioskService: KioskService, 
    private loadingService: LoadingService, private zone: NgZone, private fb: FormBuilder,
    @Inject(PLATFORM_ID) public platformId: Object, private sessionTimeoutService: SessionTimeoutService) { }

  loginForm = this.fb.group({
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [Validators.required, Validators.minLength(6)]),
  });

  loginKioskForm = this.fb.group({
    username: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required, Validators.minLength(6)]),
  });

  private authenticateUser(data: LoginResponseDTO) {
    this.authService.saveUserDetails(data as LoginResponseDTO);
    this.authService.setAuthenticated(true);
  }

  async googleLogin(response: CredentialResponse) {
    this.authService.loginGoogleUser(response).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.authenticateUser(result.data as LoginResponseDTO);
        }
      },
      complete: () => {
        const isAuthenticated: boolean = this.authService.isUserAuthenticated();
        if (isAuthenticated) {
          this.zone.run(() => {
            this.loadingService.hide();
            this.toastr.success("Login Successful", 'Success', toastrConfig);
            this.sessionTimeoutService.cleanup();
            this.sessionTimeoutService.resetTimer();
            this.router.navigate(['/']);
          });

        } else {
          this.zone.run(() => {
            this.toastr.error("Unable to sign you in", "Error", toastrConfig);
          });
        }
      },
      error: () => {
        this.toastr.error("Something went wrong", "Error", toastrConfig);
        this.loadingService.hide();
      }
    })
  }

  navigateToForgotPassword(){
    this.router.navigate(['forgot-password']);
  }

  login() {
    // redirect user to the page user left before logging in
    const redirectUrl = localStorage.getItem('redirectUrl') || '/';
    this.loginFormSubmitted = true;
    if(this.isKiosk && !this.loginKioskForm.valid) return ;
    if(!this.isKiosk && !this.loginForm.valid) return;
      this.authService.logOut();
      this.loadingService.show();
      const formValue = this.isKiosk ? this.loginKioskForm : this.loginForm.value;
      const loginData: LoginDTO = {
        email: (this.isKiosk ? this.loginKioskForm.value.username : this.loginForm.value.email) as string, 
        password: (this.isKiosk ? this.loginKioskForm.value.password : this.loginForm.value.password) as string
      };
      this.authService.loginUser(loginData, this.isKiosk).pipe(first()).subscribe({
        next: (result: ResponseDTO) => {
          if (result.status) {
            this.authenticateUser(result.data as LoginResponseDTO);
          }
          else {
            this.toastr.error(result.message, "Error", toastrConfig);
          }
          this.loadingService.hide();
        },
        complete: () => {
          const isAuthenticated: boolean = this.authService.isUserAuthenticated();
          if (isAuthenticated) {
            const user = this.authService.getUserDetails();
            this.sessionTimeoutService.cleanup();
            this.sessionTimeoutService.resetTimer();
            this.toastr.success("Login Successful", 'Success', toastrConfig);
            if (user.role === "kiosk") {
                this.kioskService.getKioskById(Number(user.id)).pipe(first()).subscribe({
                next: (result: ResponseDTO) => {
                  if (result.status) {
                   let kiosk = result.data as Kiosk;
                   this.loadingService.hide();
                   this.router.navigate(['kiosk', kiosk.outletSlug]);
                  }
                  else{
                    this.toastr.error(result.message, "Error", toastrConfig);
                    this.loadingService.hide();
                  }
                }
              })
            }
            else if(redirectUrl){
              this.router.navigate([redirectUrl])
              localStorage.removeItem('redirectUrl'); 
            }
            else {
              this.loadingService.hide();
              this.router.navigate(['/']);
            }
          }
        },
        error: () => {
          this.toastr.error("Something went wrong", "Error", toastrConfig);
          this.loadingService.hide();
        }
      });
  }
}
