<!-- px-5 md:px-12 xl:px-12 2xl:px-24 py-[22px] -->
<div class=" ">
    <app-header></app-header>
</div>
<div class="pt-[70px] flex w-full h-full">
    <!-- image Div -->
    <div class="hidden md:block md:w-[45%]">
        <img class="object-cover w-full h-full" src="https://res.cloudinary.com/dw5cv0yz0/image/upload/v1718293821/VendBloc/createaccount_dzavgy.png" alt="Auth Image">
    </div>
    <!-- content div -->
    <div class="flex flex-col w-full lg:w-[55%] h-full pt-3 lg:pt-10 xl:pt:20 px-8 md:px-24 lg:px-20 xl:px-28 bg-white dark:bg-vendblocprimayDark">
       <ng-content></ng-content>
    </div>
</div>