import { Component, Input, OnDestroy } from '@angular/core';
import { TableComponent } from "../../table/table.component";
import { OutletComponent } from "../outlet/outlet.component";
import { PrimeTableColumn } from '@models/prime-interface/prime-interface';
import { OutletService, ConfirmDialogService, LoadingService } from '@data-access/services';
import { Subject, first, takeUntil } from 'rxjs';
import { Outlet, ResponseDTO } from '@models/index';
import { Worker, WorkerCreate } from '@models/worker/worker';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { ZorroTableComponent } from "../../zorro-table/zorro-table.component";

@Component({
  selector: 'app-outlet-workers',
  standalone: true,
  templateUrl: './outlet-workers.component.html',
  styleUrl: './outlet-workers.component.css',
  imports: [TableComponent, OutletComponent, ReactiveFormsModule, DialogModule, DropdownModule, ZorroTableComponent]
})
export class OutletWorkersComponent implements OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  @Input() church: string = '';


  tableCols: PrimeTableColumn[] = [
    { field: 'userName', header: 'User' },
    { field: 'outletName', header: 'Outlet' },
    { field: 'role', header: 'Role' },
    { field: 'userEmail', header: 'Email' },
  ];

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  workers: Worker[] = [];
  outletId: number = 0;
  outlet: Outlet | null = null;
  roles: Object[] = [{ name: 'Admin', id: 1 }, { name: 'Cashier', id: 2 }, { name: 'Dispatch', id: 3 }];
  addWorkerDialog: boolean = false;
  private $destroy = new Subject<void>();

  showDialog() {
    this.addWorkerDialog = !this.addWorkerDialog;
  }

  constructor(private outletService: OutletService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private confirmDialogService: ConfirmDialogService) {

  }

  ngOnInit(): void {
    this.route.params.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(params => {
      this.outletId = +params['id'];
      this.fetchOutlet();
    });
  }

  fetchOutlet(): void {
    this.outletService.outlet$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((result) => {
      const r = result as Outlet
      if (r.id === this.outletId) {
        this.outlet = result;
        this.fetchWorkers();
      }
    });
  }

  createWorkerForm = new FormGroup({
    userEmail: new FormControl(),
    role: new FormControl()
  });

  createUser() {
    const formData = this.createWorkerForm.value;
    const newWorker: WorkerCreate = {
      outletId: this.outlet?.id as number,
      userEmail: formData.userEmail,
      role: formData.role?.name
    };
    this.loadingService.show();
    this.outletService.createOutletWorker(newWorker).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.createWorkerForm.reset();
          this.loadingService.hide();
          this.toastr.success('Successful Operation');
          this.fetchWorkers();
          this.showDialog();
        }
        else {
          this.toastr.error(result.message);
        }
      },
      error: (e) => {
        console.log(e);
      }
    });
  }

  removeWorker(id: number) {
    this.confirmDialogService.open({
      title: 'Confirm Removal',
      message: 'Are you sure you want to remove this worker?',
      confirmLabel: 'Delete',
      cancelLabel: 'Cancel'
    }).then((result) => {
      if (result) {
        this.loadingService.show();
        this.outletService.removeOutletWorker(id).pipe(first()).subscribe({
          next: (result: ResponseDTO) => {
            if (result.status) {
              this.toastr.success('Worker removed successfully');
              this.loadingService.hide();
              this.fetchWorkers()
            }
            else {
              this.loadingService.hide();

              this.toastr.error(result.message);
            }
          },
          error: (e) => {
            this.loadingService.hide();

            console.log(e);
          }
        });
      }
    });
  }

  fetchWorkers() {
    this.outletService.getOutletWorkers(this.outlet?.id as number).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        this.workers = result.data as Worker[];
      },
      error: (e) => {
        console.log(e);
      }
    });
  }
}
