import { Component } from '@angular/core';
import { Assets } from '@components/shared/assets';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  date = new Date().getFullYear();
  LIGHT_MODE_LOGO = Assets.LOGOS.LIGHT_MODE_LOGO;
}
