<app-dialog [visible]="showDialog" header="Order Invoice" width="60%" [showFooter]=false (confirm)="onConfirmAction()"
  (cancel)="onCancelAction()" (hide)="onCancelAction()">
  <div class="flex flex-col space-y-3 lg:mt-2">
    @if(userRole === (roleEnum.STORE_OWNER ||  roleEnum.UNCLE_SHENOR)){
    <div class="mb-2 form_div">
      <app-dropdown-field [formControl]="getOrderStatusForm.controls.status" [options]="orderStatus" [label]="'Status'"
        [placeHolder]="'Select a status'" (onChangeEvent)="statusChanged($event)">
      </app-dropdown-field>
    </div>
    }
    <div class="flex flex-col space-y-1 md:space-y-3">
      <div class="flex flex-col justify-between space-y-1 md:flex-row md:space-y-0">
        <span>
          <span class="font-semibold"> ID:</span>
          {{order?.id}}
        </span>
        <span>
          <span class="font-semibold">Stores: </span>
          ({{order?.outlet}})
        </span>
      </div>
      <div class="flex flex-col justify-between space-y-1 md:flex-row md:space-y-0">
        <span>
          <span class="font-semibold">
            Name:
          </span> {{order?.customer?.name}}
        </span>
        <span>
          <span class="font-semibold"> Phone:</span>
          {{order?.customer?.phone}}
        </span>
        <!-- <span>Customer Name: {{order?.customer?.name }}</span>
        <span>Customer Phone: {{order?.customer?.phone}}</span> -->
      </div>
      <div class="flex flex-col justify-between space-y-1 md:flex-row md:space-y-0">
        <span>
          <span class="font-semibold">
            Payment Status:
          </span> {{splitCamelCase(order?.status)}}
        </span>
        <span>
          <span class="font-semibold"> Order Cost:</span>
          {{order?.amount}}
        </span>
      </div>

    </div>

    @for (item of createRange(order?.totalOrder || 1); track $index) {
    <div class="flex justify-center">
      <!-- @if(order?.totalOrder ?? 0 >= 1){ -->
      <span class="font-semibold">Order {{$index + 1}}</span>
      <!-- } -->
    </div>

    <div>
      <!-- <app-table [cols]="tableCols" [tableData]="order?.products || []" tableNamePlural="Orders"
        tableNameSingular="Order">

      </app-table> -->


      <p-table [value]="order?.products || []" [tableStyle]="{ 'min-width': '30rem' }">
        <ng-template pTemplate="header">
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Total</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
          <!-- @if(product.group === $index+1){ -->
          <tr>
            <td>{{rowIndex + 1 }}</td>
            <td>{{ product.product }}</td>
            <td>{{ product.amount }}</td>
            <td>{{ product.orderQuantity }}</td>
            <td>{{ product.orderQuantity * product.amount}}</td>
          </tr>
          <!-- } -->
        </ng-template>
      </p-table> 

    </div>
    }
    <div class="flex justify-between">
      @if(order?.deliveryMode?.toLowerCase() === 'deliver'){
      <span>Delivery Cost: {{order?.deliveryCost}}</span>
      }
      <span>Order Cost: {{order?.amount}}</span>
    </div>
    <div class="flex justify-between">
      <span>Total Cost: {{order?.currency}} {{(order?.deliveryCost || 0) + (order?.amount || 0)}}</span>
    </div>
  </div>
</app-dialog>

<style>
  :host ::ng-deep .p-steps {
    padding: 1rem;
  }

  :host ::ng-deep .p-steps .p-steps-item::before {
    border-top: 2px solid #e9ecef;
    content: ' ';
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1px;
  }

  :host ::ng-deep .p-steps .p-steps-item.p-highlight::before {
    border-top-color: #580c8b;
  }

  .step-label-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .step-label {
    font-size: 0.875rem;
    color: #6b7280;
  }

  .completed-icon {
    color: #580c8b;
  }

  :host ::ng-deep .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #580c8b;
    color: #ffffff;
  }
</style>

<!-- <p-steps [model]="items" [activeIndex]="currentStep" [readonly]="false" (activeIndexChange)="onStepChange($event)">
  <ng-template pTemplate="item" let-item>
    <div class="step-label-container">
      <i [class]="item.icon" [ngClass]="{'completed-icon': isStepCompleted(item)}"></i>
      <span class="step-label">{{item.label}}</span>
    </div>
  </ng-template>
</p-steps> -->