<!-- px-5 md:px-12 xl:px-12 2xl:px-24 py-[12px] md:py-[22px] -->

<div class="">
    <app-header [cartCount]="cartCount" (emitCartEvent)="cartEvent()" [showCart]="showCart"></app-header>
</div>


<div class="pt-[80px] flex  flex-col items-center">
    <div class="w-full max-w-[1500px]">
        <div class="">
            <ng-content></ng-content>
        </div>
    </div>
    </div>