import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, InventoryService, LoadingService, LocalService, OrderService, OutletService } from '@data-access/services';
import { Cart, CartHolder, CreateOrder, DeliveryLocation, Inventory, InventoryByCategory, Outlet, ProductCategory, ResponseDTO, User } from '@models/index';
import { ToastrService } from 'ngx-toastr';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Subject, first, takeUntil } from 'rxjs';
import { SideMenuItemComponent } from "../side-menu-item/side-menu-item.component";
import { DialogModule } from 'primeng/dialog';
import { InfoComponent } from "../info/info.component";
import { BadgeModule } from 'primeng/badge';
import { PaymentPercentage } from '@utils/index';
import { ConfirmDialogService } from '../../data-access/services/confirm-dialog/confirm-dialog.service';
import { CartCardComponent } from '../shared/cart-card/cart-card.component';
import { InfoOverlayComponent } from "../shared/info-overlay.component";
import { formatCurrency, toastrConfig } from '../../utils/shared/shared';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ProductQuantityComponent } from "../shared/product-quantity/product-quantity.component";
import { PrintService } from '../../services/print.service';
import { Assets } from '@components/shared/assets';
import { DeliveryOptionEnum } from '@utils/enum/delivery-option';
@Component({
  selector: 'app-order',
  standalone: true,
  templateUrl: './order.component.html',
  styleUrl: './order.component.css',
  imports: [SideMenuItemComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    CardModule,
    OverlayPanelModule, DialogModule, InfoComponent, BadgeModule, CartCardComponent, InfoOverlayComponent, AvatarModule, AvatarGroupModule, ProductQuantityComponent]
})
export class OrderComponent implements OnInit, OnDestroy {
  deliveryOptionEnum = DeliveryOptionEnum

  DEFAULT_RESTAURANT_IMG = Assets.IMAGES.DEFAULT_RESTAURANT_IMAGE
  cartDialog: boolean = false;
  isAuthenticated = false;
  deliveryLocation: string = '';
  showDeliveryLocationDialog: boolean = false;
  updateProfileDialog: boolean = false;
  outlet: Outlet | undefined = undefined;
  categories: ProductCategory[] = [];
  allproducts: Inventory[] = [];
  cart: Cart[][] = [];
  deliveryFee: number = 0;
  ordersCost: number = 0;
  totalCost: number = 0;
  totalCostString: string = '';
  currentCategory = -1;
  selectedDeliveryMode: number = 0;
  selectedDeliveryLocation: number = 0;
  serviceCharge: number = 0;
  baseServiceCharge: number = 150;
  user: User | null = null;
  activeCategory: number = 0;
  confirmDeliveryInfo: boolean = false;
  searchQuery: string = '';
  filteredCategories: ProductCategory[] = [];
  filteredProducts: Inventory[] = [];
  profileUpdateFormSubmitted : boolean = false;
  isLoading: boolean = false;
  orderInstructions: { deliveryInstructions: string, vendorInstructions: string } = { deliveryInstructions: '', vendorInstructions: '' };
  deliveryInstructions : string = '';
  vendorInstructions : string = ''

  // orderInstructionsForm = new FormGroup({
  //   deliveryInstructions: new FormControl(),
  //   vendorInstructions: new FormControl()
  // });

  now : Date = new Date();

  overlayTitle: string = '';
  overlayDescription: string = '';

  constructor(public toastr: ToastrService,
    public orderService: OrderService,
    private outletService: OutletService,
    private route: ActivatedRoute,
    public loadingService: LoadingService,
    private authService: AuthService,
    private inventoryService: InventoryService,
    public local: LocalService,
    private confirmDialogService: ConfirmDialogService,
    private router: Router,
    public printService: PrintService
  ) {
  }

  private $destroy = new Subject<void>();

  ngOnInit() {
    this.loadingService.show();
    this.isLoading = true;
    this.route.params.subscribe(params => {
      this.fetchOutlet(params['slug']);
    });

    this.authService.user$.pipe(takeUntil(this.$destroy)).subscribe({
      next: (result) => {
        this.user = result as User;
        console.log(result);
        this.profileUpdateForm.patchValue({ phone: this.user?.phone, address: this.user?.address, email: this.user?.email });
      },
      error: () => {
      }
    });
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

  formatCurrency = formatCurrency;

  newOrder() {
    if (this.cartDialog) {
      this.cartDialog = false;
    }
    if (this.cart[this.cart.length - 1]?.length) {
      this.cart.push([]);
      this.toastr.info('Starting a new order', 'New Order');
    }
    else {
      this.toastr.info('You already started a new order', 'New Order');
    }
  }

  profileUpdateForm = new FormGroup({
    address: new FormControl(),
    email: new FormControl('',[Validators.email, Validators.required]),
    phone: new FormControl(),
    acceptTerms: new FormControl(false)
  })



  deleteOrder(index: number) {
    this.confirmDialogService.open({
      title: 'Delete Order',
      message: `Are you sure you want to delete order ${index + 1}?`,
      confirmLabel: 'Yes, Confirm',
      cancelLabel: 'No, Cancel',
      confirmColor: '#FF2230',
      cancelTextColor: '#580c8b',
      cancelBorderColor: '#580c8b'
    }).then((result) => {
      if (result) {
        this.cart.splice(index, 1);
        this.saveCartToLocalStorage();
        this.calculateTotalCost();
      }
    });
  }
  sortOutExistingCart() {
    const existingCart = this.local.getData("cart");
    if (existingCart !== null && existingCart !== undefined) {
      const savedCart: CartHolder = JSON.parse(existingCart);
      // Check if cart is still valid (not expired and from same outlet)
      if ((!this.isOlderThanTenMinutes(savedCart.timestamp) && savedCart.outletId === this.outlet?.id)) {
        // Case 1: User is logged in and cart belongs to them
        if (this.user && savedCart.userId === this.user.id) {
          this.loadCartItems(savedCart);
        } 
        // Case 2: Cart has no user ID (created while not logged in)
        else if (!savedCart.userId || savedCart.userId === null || savedCart.userId === undefined) {
          // If user just logged in, update the cart with user ID
          if (this.user) {
            savedCart.userId = this.user.id;
            this.local.saveData("cart", JSON.stringify(savedCart));
          }
          this.loadCartItems(savedCart);
        }
      } else {
        this.local.removeData("cart");
      }
    }
  }
  
  // Extract cart loading logic to reuse it
  private loadCartItems(savedCart: CartHolder) {
    this.cart = savedCart.cart;
    console.log(this.cart)
    const flattenedOrders = this.cart.flat();
    // Extract the 'price' property from each object and sum them up
    this.ordersCost = flattenedOrders.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.price;
    }, 0);
    this.calculateTotalCost();
  }

  
  isOlderThanTenMinutes(millisToCheck: number): boolean {
    const THIRTY_MINUTES = 30 * 60 * 1000;
    const now = new Date().getTime();
    const thirtyMinutesAgo = now - THIRTY_MINUTES;
    return millisToCheck < thirtyMinutesAgo;
  }

  showCartDialog() {
    this.cartDialog = !this.cartDialog;
  }

  saveCartToLocalStorage(): void {
    const now = new Date().getTime();
    const saveCart: CartHolder = {
      cart: this.cart,
      outletId: this.outlet?.id as number,
      userId: this.user?.id as string,
      timestamp: now
    }
    this.local.saveData("cart", JSON.stringify(saveCart));
  }

  updateProfile() {
    this.profileUpdateFormSubmitted = true;
    console.log(this.profileUpdateForm.value);
    if(this.profileUpdateForm.invalid)return;
    this.loadingService.show()
    const formValue = this.profileUpdateForm.value;

    if (!this.user?.email && !formValue.email) {
      this.loadingService.hide()
      this.toastr.warning("Email is required");
      return;
    }

    if (!formValue.address || !formValue.phone) {
      this.loadingService.hide()
      this.toastr.warning("Address and phone are required");
      return;
    }

    this.loadingService.hide()
    this.toggleProfileModal();
    this.confirmDeliveryInfo = true;

    if (this.user) {
      const updatedFields: Partial<User> = {};

      if (this.user.address !== formValue.address) {
        updatedFields.address = formValue.address;
      }
      if (this.user.phone !== formValue.phone) {
        updatedFields.phone = formValue.phone;
      }
      if (Object.keys(updatedFields).length > 0) {
        this.user = { ...this.user, ...updatedFields };
        this.authService.setUser(this.user);
      }
    }
    else {
      this.user = {
        email: formValue.email ?? '',
        address: formValue.address,
        phone: formValue.phone,
        name: '',
        id: '',
        role: ''
      };
      this.authService.setUser(this.user);
    }
    // this.checkout();
  }

  fetchReferralGifts(userId: string) {
    this.authService.getEligibleReferralPackages(userId).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          const gifts = result.data as string[];
          gifts.forEach(element => {
            this.toastr.success(element);
          });
        }
      },
      error: () => {
        console.log("Something went wrong");
      }
    });
  }

  fetchOutlet(outletSlug: string) {
    this.outletService.getOutletBySlug(outletSlug).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.outlet = result.data as Outlet;
          this.fetchInventoryByCategory(this.outlet.id);
          this.sortOutExistingCart();
          this.loadingService.hide();
          this.isLoading = false;
        }
        else {
          console.log("something went wrong");
        }
      },
      error: (ex) => {
        console.log(ex);
      }
    });
  }

  fetchInventoryByCategory(outlet: number) {
    this.inventoryService.getInventoryByCategoryByOutlet(outlet).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          const inventory = result.data as InventoryByCategory[];
          this.allproducts = inventory.flatMap(x => x.inventories);
          this.categories = inventory.map(x => ({
            name: x.productCategory,
            id: x.productCategoryId,
            quantity: x.inventoryQuantity
          } as ProductCategory));
          this.initializeProductsCategories();
        }
      }
    });
  }

  initializeProductsCategories() {
    this.filteredCategories = [];
    this.filteredProducts = [];
    this.filteredCategories.push({ name: 'All', id: 0, quantity: this.allproducts.length });
    this.filteredCategories.push(...this.categories);
    this.filteredProducts = this.allproducts;
  }

  validateAddressAndPhone(): boolean {
    if (!this.user?.phone && !this.profileUpdateForm.value.phone) {
      return false;
    }
    return true;
  }

  toggleDeliveryLocationModal(show: boolean) {
    if(show === this.showDeliveryLocationDialog) return;
    if(!show) {
      if(this.deliveryLocation === '') {
        this.toastr.warning('Please select a location');
        return;
      }
    }
    this.showDeliveryLocationDialog = show;
  }

  deliveryModeChanged(selectedDeliveryMode: number, index?: number): void {
    if (selectedDeliveryMode === this.deliveryOptionEnum.DELIVERY) {
      this.handleDeliveryMode(index);
    }
    else{
      this.showDeliveryLocationDialog = false;
    }
    this.totalCost = this.calculateTotalWithDelivery(selectedDeliveryMode === this.deliveryOptionEnum.DELIVERY);
    this.updateServiceCharge();
    this.selectedDeliveryMode = selectedDeliveryMode;
  }

  private handleDeliveryMode(index?: number): void {
    if (!this.outlet?.deliveryLocations?.length) {
      return;
    }
    if (index !== undefined) {
      this.setDeliveryFeeForLocation(this.outlet.deliveryLocations[index]);
      return;
    }
    if (this.outlet.deliveryLocations.length === 1) {
      const location = this.outlet.deliveryLocations[0];
      this.deliveryLocation = location.location;
      this.setDeliveryFeeForLocation(location);
    } else {
      this.toggleDeliveryLocationModal(true);
    }
  }

  private setDeliveryFeeForLocation(location: DeliveryLocation): void {
    const isNightTime = new Date().getHours() >= 18;
    if(this.selectedDeliveryMode === this.deliveryOptionEnum.DELIVERY) {
      this.totalCost = this.calculateTotalWithDelivery(false);
    }
    this.deliveryFee = isNightTime && location?.nightAmount !== 0
      ? location.nightAmount
      : location.dayAmount;
      this.deliveryLocation = location.location;
  }

  private calculateTotalWithDelivery(isDelivery: boolean): number {
    return isDelivery ? this.totalCost + this.deliveryFee : this.totalCost - this.deliveryFee;
  }

  categoryProducts(event: Event, productCategoryId: number) {
    event.preventDefault();
    if (productCategoryId === this.activeCategory) return;
    this.activeCategory = productCategoryId;
    this.currentCategory = productCategoryId;
    if (productCategoryId === 0) {
      this.filteredProducts = this.allproducts;
    }
    else {
      this.filteredProducts = this.allproducts.filter(x => x.productCategoryId === productCategoryId);
    }
  }

  updateServiceCharge() {
    const gateway = PaymentPercentage.SquadPay;
    const gatewayCharge = (this.ordersCost * (gateway / 100));
    const vendBlocCharge = (this.ordersCost * (PaymentPercentage.VendBloc / 100));
    let serviceCharge = gatewayCharge + vendBlocCharge;
    if (this.ordersCost > 2000 && gateway == PaymentPercentage.Paystack) {
      serviceCharge = serviceCharge + 100;
    }
    this.serviceCharge = Math.round(serviceCharge);
  }

  clearCart() {
    this.confirmDialogService.open({
      title: 'Clear Cart',
      message: 'Are you sure you want to clear your cart?',
      confirmLabel: 'Yes, Confirm',
      cancelLabel: 'No, Cancel',
      confirmColor: '#FF2230',
      cancelTextColor: '#580c8b',
      cancelBorderColor: '#580c8b'
    }).then((result) => {
      if (result) {
        this.resetCart();
      }
    });
  }

  resetCart() {
    this.cart = [];
    this.local.removeData("cart");
    this.ordersCost = 0;
    this.deliveryModeChanged(0);
    this.calculateTotalCost();
  }

  calculateTotalCost() {
    this.updateServiceCharge();
    this.totalCost = this.ordersCost + this.serviceCharge;
    if (this.selectedDeliveryMode === this.deliveryOptionEnum.DELIVERY) {
      this.totalCost += this.deliveryFee;
    }
  }

  addProductToCart(product: Inventory) {
    if (product.orderQuantity < 1) {
      this.toastr.warning("Please enter a valid quantity");
      return;
    }
    if (!this.cart.length) {
      this.cart.push([]);
    }
    const cartItem: Cart = {
      inventoryId: product.id,
      productImage: product.productImage,
      productId: product.productId,
      product: product.product,
      salesPrice: product.salesPrice,
      price: product.salesPrice * product.orderQuantity,
      group: this.cart.length - 1,
      orderQuantity: product.orderQuantity
    }
    this.addToCart(cartItem)
    product.orderQuantity = 1;
  }

  repeatOrder() {
    if (this.cart.length < 2) return;
    const previousOrder: Cart[] = this.cart[this.cart.length - 2];
    for (let index = 0; index < previousOrder.length; index++) {
      this.addToCart(previousOrder[index]);
    }
  }

  addToCart(cartItem: Cart, preferredCart?: number) {
    let orderCost: number = 0;
    if (this.cart.length) {
      let currentCart: Cart[];
      // Check if the user wants to insert in specific order group in cart
      if (preferredCart) {
        if (preferredCart > this.cart.length - 1) {
          this.toastr.warning('unsupported operation');
          return;
        }
        currentCart = this.cart[preferredCart]
      }
      // Else inserts into the last order group
      else {
        currentCart = this.cart[this.cart.length - 1]
      }
      //Checks if product is in the selected order group

      const isProductInCurrentCart = currentCart.findIndex(x => x.productId === cartItem.productId);
      if (isProductInCurrentCart < 0) {
        currentCart.push(cartItem);
        orderCost = cartItem.price
      }
      else {
        currentCart[isProductInCurrentCart].orderQuantity += cartItem.orderQuantity;
        currentCart[isProductInCurrentCart].price += cartItem.price;
        orderCost = cartItem.price;
      }
    }
    this.ordersCost += orderCost;
    this.calculateTotalCost();
    this.saveCartToLocalStorage()
    this.toastr.success('Added To Cart', 'Success', toastrConfig);
  }

  handleProductQuantityIncrementDecrement(event: { cartItem: Inventory, increment: boolean }) {
    const index = this.filteredProducts.findIndex(x => x.productId === event.cartItem.productId);
    if (event.increment) {
      this.filteredProducts[index].orderQuantity = this.filteredProducts[index].orderQuantity + 1;
      this.filteredProducts[index].orderPrice = formatCurrency(this.filteredProducts[index].orderQuantity * this.filteredProducts[index].salesPrice, 'NGN')
    }
    else {
      if (this.filteredProducts[index].orderQuantity > 1) {
        this.filteredProducts[index].orderQuantity = this.filteredProducts[index].orderQuantity - 1;
        this.filteredProducts[index].orderPrice = formatCurrency(this.filteredProducts[index].orderQuantity * this.filteredProducts[index].salesPrice, 'NGN')
      }
    }
    this.calculateTotalCost();
  }

  removeFromCart(event: { cartItem: Cart, index: number }) {
    this.ordersCost -= event.cartItem.price;
    const affectedCartOrder: Cart[] = this.cart[event.index];
    const isFirstOrder = event.index === 0;
    const itemIndex = affectedCartOrder.findIndex(x => x.productId === event.cartItem.productId);
    if (itemIndex !== -1) {
      affectedCartOrder.splice(itemIndex, 1);
      if (affectedCartOrder.length < 1) {
        this.cart.splice(event.index, 1);
        if (isFirstOrder) {
          this.resetCart();
        }
      }
    }
    this.saveCartToLocalStorage();
    this.calculateTotalCost();
  }

  navigateBack() {
    this.router.navigate(['/explore']);
  }

  cartItemIncrementDecrement(event: { cartItem: Cart, increment: boolean; }) {
    this.updateCartQuantity(event, 1)
  }


  updateCartQuantity(event: { cartItem: Cart, increment: boolean; }, multiples: number) {
    const orderGroup = event?.cartItem?.group ?? 0;
    const targetOrder: Cart[] = this.cart[orderGroup];
    const isProductInTargetOrder = targetOrder.findIndex(x => x.productId === event?.cartItem?.productId);
    if (isProductInTargetOrder < 0) {
      this.toastr.info('Product not present in cart');
    }
    else {
      // Sales price is the unit price
      const productSalesPrice = event.cartItem.salesPrice * multiples
      if (event?.increment) {
        targetOrder[isProductInTargetOrder].orderQuantity += multiples;
        targetOrder[isProductInTargetOrder].price += productSalesPrice;
        //        this.totalCost += productSalesPrice;
        this.ordersCost += productSalesPrice;
      }
      else {
        if (event.cartItem.orderQuantity < 2) return;
        targetOrder[isProductInTargetOrder].orderQuantity -= multiples;
        targetOrder[isProductInTargetOrder].price -= productSalesPrice;
        //    this.totalCost -= productSalesPrice;
        this.ordersCost -= productSalesPrice;
      }
    }
    this.saveCartToLocalStorage();
    this.calculateTotalCost();
  }

  toggleProfileModal() {
    if(this.selectedDeliveryMode === this.deliveryOptionEnum.PICK_UP) return;
    this.updateProfileDialog = !this.updateProfileDialog;
    if (this.updateProfileDialog) {
      this.cartDialog = false;
    }
  }

  updateOrderInstructions(event: { deliveryInstructions: string, vendorInstructions: string }) {
    console.log(event);
    if (event.deliveryInstructions) {
      this.orderInstructions.deliveryInstructions = event.deliveryInstructions;
    }
    if (event.vendorInstructions) {
      this.orderInstructions.vendorInstructions = event.vendorInstructions;
    }
  }

  checkout() {
    console.log(this.user)
    if (this.profileUpdateForm.invalid && this.selectedDeliveryMode === this.deliveryOptionEnum.DELIVERY) {
        this.toastr.warning("Please ensure your email, phone and address are correct");
        this.toggleProfileModal();
        return;
    }
    if (this.cart.length < 1) {
      this.toastr.warning("Add Items to cart Before Checking Out");
      return;
    }
    if (!this.validateAddressAndPhone() && this.selectedDeliveryMode === this.deliveryOptionEnum.DELIVERY) {
      this.toastr.warning("Please update your phone and address");
      this.toggleProfileModal();
      return;
    }
    else {
      if (!this.confirmDeliveryInfo && this.selectedDeliveryMode === this.deliveryOptionEnum.DELIVERY) {
        this.toastr.warning("Please confirm your phone and address");
        this.toggleProfileModal();
        this.confirmDeliveryInfo = true;
        return;
      }
    }
    this.loadingService.show()
    const order: CreateOrder = {
      orders: this.cart,
      outletId: this.outlet?.id ?? 0,
      customer: {
        email: this.user?.email || this.profileUpdateForm.value.email || '',
        address: this.user?.address || this.profileUpdateForm.value.address,
        phone: this.user?.phone || this.profileUpdateForm.value.phone,
        name: this.user?.name || '',
      },
      amount: this.totalCost,
      deliveryCost: this.selectedDeliveryMode === this.deliveryOptionEnum.PICK_UP ? 0 : this.deliveryFee,
      orderCost: this.ordersCost,
      serviceCharge: this.serviceCharge,
      deliveryInstruction: this.deliveryInstructions,
      vendorInstruction: this.vendorInstructions,
      deliveryMode: this.selectedDeliveryMode
    };
    this.orderService.checkout(order).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.loadingService.hide()
          this.toastr.success('Trying to set up your payment link', 'Order Created');
          this.local.removeData("cart");
          window.location.href = result.data as string;
        }
        else {
          this.toastr.error(result.message);
          this.loadingService.hide()
        }
      },
      error: () => {
        this.loadingService.hide()
      }
    });
  }

  onPhoneInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/\D/g, '');
  }

  onSearch() {
    const query = this.searchQuery.toLowerCase().trim();

    if (query === '') {
      this.initializeProductsCategories();
    } else {
      this.filteredProducts = this.allproducts.filter(product =>
        product.product.toLowerCase().includes(query)
      );
      this.filteredCategories = this.categories.filter(category =>
        category.name.toLowerCase().includes(query)
      )
      if (this.filteredProducts.length < 1 && this.filteredCategories.length > 0) {
        this.filteredCategories = this.filteredCategories.map(category => ({
          ...category,
          quantity: this.allproducts.filter(p => p.productCategoryId === category.id).length
        }));
        this.filteredProducts = [];
        for (let index = 0; index < this.filteredCategories.length; index++) {
          const category = this.filteredCategories[index];
          this.filteredProducts.push(...this.allproducts.filter(p => p.productCategoryId === category.id))
        }
      }
      else {
        const matchingCategoryIds = new Set(this.filteredProducts.map(p => p.productCategoryId));
        this.filteredCategories = this.categories.filter(category =>
          matchingCategoryIds.has(category.id)
        ).map(category => ({
          ...category,
          quantity: this.filteredProducts.filter(p => p.productCategoryId === category.id).length
        }));
      }

    }
    this.activeCategory = this.filteredCategories.some(c => c.id === this.activeCategory)
      ? this.activeCategory
      : -1;
  }

  //   onQuantityChange($event :{id: number , quantity: number}) {
  //   const product = this.filteredProducts.find(p => p.productId === $event.id);
  //   if (product) {
  //     product.orderQuantity = $event.quantity;
  //   }
  // }

  handleDirectQuantityInput($event: { productId: number, quantity: number }) {
    const product = this.filteredProducts.find(p => p.productId === $event.productId);
    // if ($event.quantity == 0) {
    //   $event.quantity = 1;
    // }
    if (product) {
      product.orderQuantity = $event.quantity;
    }
  }

  onCartQuantityChange($event: { currentCart: number, productId: number, quantity: number }) {
    console.log($event.quantity);
    if ($event.quantity == 0) {
      $event.quantity = 1;
    }
    const cartItem = this.cart[$event.currentCart].find(p => p.productId === $event.productId);
    if (cartItem) {
      const increment = $event.quantity > cartItem.orderQuantity
      const multiple = $event.quantity - cartItem.orderQuantity
      this.updateCartQuantity({ cartItem, increment }, Math.abs(multiple))
    }
  }

  onVendorInstructionsChanged(instructions: string) {
    this.vendorInstructions = instructions;
  }

  onDeliveryInstructionsChanged(instructions: string) {
    this.deliveryInstructions = instructions;
  }
}