import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Order } from '@models/index';
import { AppDialogComponent } from "../app-dialog/app-dialog.component";
import { TableModule } from 'primeng/table';
import { StepsModule } from 'primeng/steps';
import { StepperModule } from 'primeng/stepper';
import { ButtonModule } from 'primeng/button';
import { MenuItem } from 'primeng/api';
import { AuthService, ConfirmDialogService } from '@data-access/services';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DropdownFieldComponent } from "../dropdown-field/dropdown-field.component";
import { PrimeDropdownField } from '@models/prime-interface/prime-interface';
import { OrderStatus } from '@utils/index';
import { RoleEnum } from '@utils/enum/role';

@Component({
  selector: 'app-order-invoice',
  standalone: true,
  imports: [AppDialogComponent, TableModule, StepsModule, StepperModule, ButtonModule, FormsModule, ReactiveFormsModule, CommonModule, DropdownFieldComponent],
  templateUrl: './order-invoice.component.html',
  styleUrl: './order-invoice.component.css'
})
export class OrderInvoiceComponent implements OnInit, OnChanges {

  userRole!: string;
  roleEnum = RoleEnum

  @Output() statusChangeEvent: EventEmitter<number> = new EventEmitter<number>();

  statusChanged(data: any) {
    this.statusChangeEvent.emit(data.id);
  }

  constructor(private confirmDialogService: ConfirmDialogService, private authService: AuthService) {
    this.orderStatus = this.getOrderStatus();
  }

  ngOnInit(): void {
    this.userRole = this.authService.getUserRole();
  }

  splitCamelCase(text: string | undefined): string {
    if (!text) return '';
    return text.split(/(?<=[a-z])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])/).join(' ');
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.order)
  }
  getOrderStatus(): PrimeDropdownField[] {
    const orderStatuses = Object.values(OrderStatus) as string[];
    const statuses = orderStatuses.slice(0, Object.keys(OrderStatus).length / 2).map((x, index) => ({ name: x, id: index }));
    return statuses;
  }

  getOrderStatusForm = new FormGroup({
    status: new FormControl<PrimeDropdownField | null>(null)
  })

  tableCols: any[] = [
    { field: 'name', header: 'Customer' },
    { field: 'price', header: 'Order Id' },
    { field: 'quantity', header: 'Status' },
    { field: 'total', header: 'Paid' }
  ];

  @Output() closeDialog = new EventEmitter<void>();
  @Input() order: Order | null = null;
  @Input() showStatusDropdown: boolean = true;
  @Input() showDialog: boolean = false;
  orderStatus: PrimeDropdownField[] = [];



  currentStep = 0;
  items: MenuItem[] = [
    {
      label: 'Pending',
      icon: 'pi pi-clock'
    },
    {
      label: 'Approved',
      icon: 'pi pi-check'
    },
    {
      label: 'Awaiting Pickup',
      icon: 'pi pi-box'
    },
    {
      label: 'In Delivery',
      icon: 'pi pi-truck'
    },
    {
      label: 'Delivered',
      icon: 'pi pi-check-circle'
    }
  ];

  onCancelAction() {
    this.closeDialog.emit();
  }

  onConfirmAction() {
    throw new Error('Method not implemented.');
  }

  createRange(range: number) {
    return new Array(range)
  }
}
